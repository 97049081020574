import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Terms = () => {
  return (
    <div className="flex flex-col min-h-screen text-white">
      <Header />

      <div className="flex justify-center">
        <div className="w-full max-w-2xl p-4">
          <h2 className="text-2xl font-bold mb-4 text-center">Policy on Refunds and Replacements</h2>
          <div className="list-none p-6 rounded-2xl darker-discord-background">
            <p><strong>Once you have purchased and secured an account from us, we are no longer responsible for any issues that may arise with the account.</strong> This includes situations where the account becomes locked or inaccessible after the transfer of ownership has been completed.</p>

            <h3 className="text-xl font-bold mt-4">Please be aware that:</h3>
            <ul className="list-disc pl-6 mt-2">
              <li><strong>Account Transfer:</strong> Once the account is delivered to you and the necessary steps to secure it are completed, our responsibility for the account ends. It is crucial to follow all recommended security measures to ensure the continued accessibility and security of the account.</li>
              <li><strong>No Refunds or Replacements:</strong> We do not offer refunds or replacements for accounts once they have been handed over to you. This policy is in place because, once the account is transferred, we have no control over how it is managed or any actions taken that might affect its status.</li>
              <li><strong>Customer Responsibility:</strong> It is the customer's responsibility to maintain the security and integrity of the account once it is in their possession. We recommend enabling all security features and regularly updating account details to prevent unauthorized access.</li>
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Terms;
